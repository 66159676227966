import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StarIcon from '@mui/icons-material/Star';
import image5 from '../assets/images/image5.jpg'; 
import image6 from '../assets/images/image6.jpg'; 

// Dummy Data for Courses
const courses = [
  {
    id: 1,
    image: image5,
    description: 'Comprehensive classes for all subjects from grades 6-12',
    details: 'This course covers algebra, geometry, trigonometry, and calculus. Our experienced instructors offer personalized attention to help students excel in their exams. Small batch sizes ensure focused learning.',
    subjects: ['Algebra', 'Geometry', 'Trigonometry', 'Calculus'],
    timings: 'Mon, Wed, Fri: 4:00 PM - 6:00 PM',
    specialties: ['Individual Attention', 'Advanced Problem Solving', 'Exam Preparation'],
  },
  {
    id: 2,
    image: image5,
    description: 'Coahing for JEE,NEET,CET and Navodhaya Entrance.',
    details: 'Our science classes offer an engaging blend of theory and practicals, tailored to the learning needs of middle school students. The curriculum is aligned with the latest education standards.',
    subjects: ['Physics', 'Chemistry', 'Biology'],
    timings: 'Tue, Thu: 3:00 PM - 5:00 PM',
    specialties: ['Interactive Labs', 'Conceptual Learning', 'Quiz-Based Assessments'],
  },
  {
    id: 3,
    image: image6,
    description: 'Literature, comprehension, and grammar classes for all grades',
    details: 'Improve reading, writing, and comprehension skills through interactive sessions. Our English program enhances both creative and analytical skills, helping students excel in language arts.',
    subjects: ['Literature Analysis', 'Grammar & Writing', 'Comprehension'],
    timings: 'Mon, Wed: 5:30 PM - 7:30 PM',
    specialties: ['Essay Writing', 'Creative Writing', 'Public Speaking'],
  },
];

const Tuition = () => {
  const [open, setOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleClickOpen = (course) => {
    setSelectedCourse(course);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCourse(null);
  };

  return (
    <Container sx={{ padding: '2rem' }}>
      {/* Academy Information */}
      <Box sx={{ marginBottom: '2rem', textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Welcome to AJ Elite Group Of Academy
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Founded in 2014, we offer courses designed to be simple and easy to follow, meeting the growing needs of learners.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Our content promotes side-by-side practice, ensuring active learning. We help students visualize difficult concepts in Math and Science while engaging them in activities like soft skills, personality development programs, meditation, and more to enhance their growth.
        </Typography>
      </Box>

      {/* Promotional Button */}
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SchoolIcon />}
          href="https://www.youtube.com/watch?v=your-video-link" // Replace with your YouTube video URL
          target="_blank"
          rel="noopener noreferrer"
          sx={{ borderRadius: '50px', padding: '0.75rem 2rem', textTransform: 'none' }}
        >
          Watch Our Classes on YouTube
        </Button>
      </Box>

      {/* Display Courses */}
      <Grid container spacing={4}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Card
              onClick={() => handleClickOpen(course)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={course.image}
                alt={course.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {course.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {course.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {course.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Course Details Dialog */}
      {selectedCourse && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>{selectedCourse.title}</DialogTitle>
          <DialogContent dividers>
            <CardMedia
              component="img"
              height="300"
              image={selectedCourse.image}
              alt={selectedCourse.title}
              sx={{ marginBottom: '1rem', objectFit: 'cover' }}
            />
            <Typography variant="h6" gutterBottom>
              {selectedCourse.price}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedCourse.description}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Subjects Covered:
            </Typography>
            <List sx={{ paddingLeft: 2 }}>
              {selectedCourse.subjects.map((subject, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText primary={subject} />
                </ListItem>
              ))}
            </List>
            <Typography variant="h6" gutterBottom>
              Class Timings:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ScheduleIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
              {selectedCourse.timings}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Specialties:
            </Typography>
            <List sx={{ paddingLeft: 2 }}>
              {selectedCourse.specialties.map((specialty, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary={specialty} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default Tuition;
