import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Container } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import image1 from '../assets/images/real1.jpg'; // Replace with actual images
import image3 from '../assets/images/real3.jpg'; // Replace with actual images
import image2 from '../assets/images/real2.jpg'; // Replace with actual images
import image4 from '../assets/images/real4.jpg'; // Replace with actual images
import image5 from '../assets/images/real5.jpg'; // Replace with actual images

// Dummy Data for Properties
const properties = [
  {
    id: 1,
    image: image1
  },
  {
    id: 2,
    image: image2
  },
  {
    id: 3,
    image: image3
  },
  {
    id: 3,
    image: image4
  },
  {
    id: 3,
    image: image5
  },
];

const RealEstate = () => {
  const [open, setOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handleClickOpen = (property) => {
    setSelectedProperty(property);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProperty(null);
  };

  return (
    <Container sx={{ padding: '2rem' }}>
      {/* Company Information */}
      <Box sx={{ marginBottom: '2rem', textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Welcome to Elite Real Estate
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Your trusted partner in buying, selling, and renting properties.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          With over 20 years of experience, we offer personalized services to help you find your dream home or investment property. Our team is committed to guiding you through every step of the process with professionalism and dedication.
        </Typography>
      </Box>

      {/* YouTube Button */}
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<YouTubeIcon />}
          href="https://www.youtube.com/watch?v=hSsaV0J9mKs" // Replace with your YouTube video URL
          target="_blank"
          rel="noopener noreferrer"
          sx={{ borderRadius: '50px', padding: '0.5rem 2rem' }}
        >
          Watch Property Tour
        </Button>
      </Box>

      {/* Display Properties */}
      <Grid container spacing={4}>
        {properties.map((property) => (
          <Grid item xs={12} sm={6} md={4} key={property.id}>
            <Card
              onClick={() => handleClickOpen(property)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
                borderRadius: 2
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={property.image}
                alt={property.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {property.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {property.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {property.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Property Details Dialog */}
      {selectedProperty && (
  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
    <DialogTitle>{`Property Details`}</DialogTitle>
    <DialogContent dividers>
      <CardMedia
        component="img"
        image={selectedProperty.image}
        alt={`Property ID: ${selectedProperty.id}`}
        sx={{
          width: '100%', // Full width of the dialog
          height: 'auto', // Maintain aspect ratio
          marginBottom: '1rem',
          objectFit: 'cover', // Ensures no distortion
        }}
      />
      <Typography variant="body1">
        This is a detailed view of the property.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
)}
    </Container>
  );
};

export default RealEstate;
